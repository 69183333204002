import React from 'react'
import { Post } from '../../types'
import './PostCard.css'

type Props = {
  post: Post
}

function PostCard({ post }: Props) {
  return (
    <div className="Container">
      <h1>{post.title}</h1>
      <h4>{post.username}</h4>
      <p>{post.content}</p>
    </div>
  )
}

export default PostCard
