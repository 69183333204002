import useSWR from 'swr'
import { Post } from '../../types'

const api = 'https://cloudflare-new-grad-production.coopslarhette2.workers.dev'

const BASE_API_REQUEST_HEADER = {
  'Content-Type': 'application/json',
}

export const appendParams = (baseUrl: string, params?: any) => {
  const url = new URL(baseUrl)

  if (params)
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, `${params[key]}`),
    )

  return url
}

const buildUrl = (resource: string, params?: any) => {
  const baseUrl = `${api}${resource}`

  return appendParams(baseUrl, params)
}

function fetcher(path: string) {
  const url = buildUrl(path)

  return fetch(url.toString()).then((r) => r.json())
}

export function httpPost<T>(path: string, body?: any): Promise<T> {
  const options = {
    method: 'post',
    body: JSON.stringify(body),
    headers: BASE_API_REQUEST_HEADER,
  }
  const url = buildUrl(path)

  return fetch(new Request(url.toString(), options)).then((r) => r.json())
}

export function addPost(post: Omit<Post, 'id'>) {
  return fetch(`${api}/posts`, {
    method: 'POST',
    headers: BASE_API_REQUEST_HEADER,
    body: JSON.stringify(post),
  })
}

export function usePosts() {
  const { data, error } = useSWR('/posts', fetcher)

  return {
    posts: data?.['posts'] as Post[],
    error,
    isLoading: !error && !data,
  }
}
