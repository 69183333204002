import React, { useState } from 'react'
import './App.scss'
import { addPost, httpPost, usePosts } from './api'
import PostCard from './PostCard'
import Modal from 'react-modal'

function App() {
  const { posts, error } = usePosts()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [username, setUserName] = useState('')

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  const handlePostSubmit = (event: React.MouseEvent) => {
    event.preventDefault()
    addPost({ title, content, username })
    toggleModal()
  }

  return (
    <div className="App" style={{ opacity: isModalOpen ? 0.1 : 1 }}>
      <div className="Navbar">
        <div className="Navbar-action-area">
          <h2>Cloudflare Social Media Project</h2>
          <button className="Add-post" onClick={toggleModal}>
            +
          </button>
        </div>
      </div>
      <div className="Main" onClick={() => setIsModalOpen(false)}  >
        <div className="Posts-container">
          {error ? (
            <>
              <h3>Sorry, an error has occurred:</h3>
              <p>{error.message}</p>
            </>
          ) : (
            posts
              ?.slice(0)
              .reverse()
              .map((post) => <PostCard post={post} key={post.id} />)
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        overlayClassName="Modal-overlay"
        appElement={document.getElementById('root') as HTMLElement}
      >
        <div className="Modal-content">
          <h2>Add a new post</h2>
          <div className="form">
            <label className="Input-box">
              Username
              <input
                type="text"
                name="username"
                onChange={(e) => setUserName(e.target.value)}
              />
            </label>
            <label className="Input-box">
              Title:
              <input type="text" onChange={(e) => setTitle(e.target.value)} />
            </label>
            <label className="Input-box">
              Content:
              <textarea onChange={(e) => setContent(e.target.value)} />
            </label>
            <button onClick={handlePostSubmit}>Submit</button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default App
